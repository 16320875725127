import React, { useEffect, useContext, useState } from 'react';
import { CSidebar, CSidebarBrand, CSidebarNav, CHeaderToggler } from '@coreui/react';
import SideBarNavigationItems from '../components/sidebar/SideBarNavigationItems';
import HeaderBarNavigation from '../components/header/HeaderBarNavigation';
import CieloAIBrand from '../assets/images/svg/cieloai-new-logo.svg';
import navigation from '../navigation/navigationItems';
import { CNavGroup, CNavItem } from '@coreui/react';

import { ApiDocumentationContext } from '../services/DocumentationProvider';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import SVGRenderer from '../utils/svg/SVGRenderer';
import { Clock, Pricing, Support, Vipp, Products } from '../utils/svg/iconSVG';
import AppContent from './AppContent';

const DefaultLayout = () => {
  const dispatch = useDispatch();
  let sidebarShow = useSelector((state) => state.sidebarShow);
  const location = useLocation();
  const isDocumentationPage = location.pathname.includes('/documentation');
  const { documentationData, setDocumentationData } = useContext(ApiDocumentationContext);
  const [documentationNavigation, setDocumentationNavigation] = useState(null);
  window.onresize = () => dispatch({ type: 'set', sidebarShow: window.innerWidth > 768 });

  const productIcons = {
    CallPass: <SVGRenderer iconName="Payphone" />,
    Fastlane: <SVGRenderer iconName="FastLane" />,
    Payment: <Pricing />,
    VIPP: <Vipp />,
    Ventana: <Clock />,
    Products: <Products />
  };

  useEffect(() => {
    if (documentationData) {
      const documentationNavigationList = createDocumentationNavigationList(documentationData);
      documentationNavigationList.push({
        component: CNavItem,
        name: 'Support',
        to: '/support',
        icon: (
          <div className="nav-link__Icon">
            <Support />
          </div>
        )
      });
      setDocumentationNavigation(documentationNavigationList);
    }
  }, [documentationData]);

  const createDocumentationNavigationList = (items) => {
    return items.map((item, index) => {
      const newItem = {
        component: CNavItem,
        name: item.productGroup,
        to: `#${index}`,
        icon: <div className="nav-link__Icon">{productIcons[item.productGroup]}</div>
      };

      if (item.documentations) {
        newItem.component = CNavGroup;
        newItem.items = item.documentations.map((documentation, subIndex) => ({
          component: CNavGroup,
          name: documentation.productOption,
          to: `#${documentation.sectionName}`,
          icon: <div className="nav-link__NoIcon"></div>,
          items: documentation.endpoints.map((endpoint, endpointIndex) => ({
            component: CNavItem,
            name: endpoint.action,
            to: `#${documentation.sectionName}-${endpoint.action.split(' ')[0].toLowerCase()}`,
            icon: <div className="nav-link__NoIcon ml-4"></div>
          }))
        }));
      }

      return newItem;
    });
  };

  return (
    <>
      {!isDocumentationPage ? (
        <CSidebar className="Dashboard-Sidebar" position="fixed" visible={sidebarShow}>
          <CSidebarBrand>
            <div className="Brand-container">
              <img src={CieloAIBrand} alt="Cielo Brand" style={{ maxWidth: '172px' }} />
              <CHeaderToggler
                className="ps-1 d-flex d-lg-none close-menu"
                onClick={() => dispatch({ type: 'set', sidebarShow: !sidebarShow })}
              >
                X
              </CHeaderToggler>
            </div>
          </CSidebarBrand>
          <CSidebarNav>
            <SideBarNavigationItems items={navigation} />
          </CSidebarNav>
        </CSidebar>
      ) : (
        documentationNavigation && (
          <CSidebar className="Dashboard-Sidebar" position="fixed" visible={sidebarShow}>
            <CSidebarBrand>
              <div className="Brand-container">
                <img src={CieloAIBrand} alt="Cielo Brand" style={{ maxWidth: '172px' }} />
                <CHeaderToggler
                  className="ps-1 d-flex d-lg-none close-menu"
                  onClick={() => dispatch({ type: 'set', sidebarShow: !sidebarShow })}
                >
                  X
                </CHeaderToggler>
              </div>
            </CSidebarBrand>
            <CSidebarNav>
              <SideBarNavigationItems items={documentationNavigation} isDocumentationPage={true} />
            </CSidebarNav>
          </CSidebar>
        )
      )}

      <div className="wrapper d-flex flex-column min-vh-100 DashboardContent">
        <HeaderBarNavigation />
        <div className="body flex-grow-1">
          <AppContent />
        </div>
      </div>
    </>
  );
};

export default DefaultLayout;
