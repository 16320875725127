import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate, useParams } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import {
  CardElement,
  Elements,
  useElements,
  useStripe,
  PaymentElement
} from '@stripe/react-stripe-js';
import { CToast, CToastBody, CToastClose } from '@coreui/react';
import Buttons from '../components/buttons/Buttons';
import { usePayHook } from '../hooks/PayHooks/usePayHook';
import { decimalPriceNumber } from '../utils/dataFormat/RuleFormat';
import cieloLogo from '../assets/images/svg/cieloAiLogo.svg';
import stripeLogo from '../assets/images/svg/stripe.svg';
import axios from 'axios';
import { useToastHook } from '../hooks/useToastHook';
import { useRef } from 'react';

axios.defaults.baseURL = process.env.REACT_APP_CIELO_API_URL;

const CARD_OPTIONS = {
  iconStyle: 'solid',
  style: {
    base: {
      iconColor: '#71f4af',
      color: '#000000',
      fontWeight: 400,
      fontFamily: 'Poppins, Open Sans, sans-serif',
      fontSize: '16px',
      fontSmoothing: 'antialiased',
      borderColor: '#e9e9ed',
      ':-webkit-autofill': {
        color: '#fce883'
      },
      '::placeholder': {
        color: '#cacaca'
      }
    },
    invalid: {
      iconColor: '#e9356e',
      color: '#e9356e'
    }
  }
};

const CardField = ({ onChange }) => (
  <div className="FormRow">
    <CardElement options={CARD_OPTIONS} onChange={onChange} />
  </div>
);
CardField.propTypes = {
  onChange: PropTypes.func
};

export function CheckoutForm({ detailPay }) {
  const { toast, handleToastState } = useToastHook();
  const stripe = useStripe();
  const elements = useElements();
  const [error] = useState(null);
  const [processing, setProcessing] = useState(false);
  const navigate = useNavigate();
  let params = useParams();
  let Intents = useRef();
  useEffect(() => {
    Intents.current = JSON.parse(localStorage.getItem('CieloIntent'));
    if (!Intents.current || (Intents.current && Intents.current.key !== params.stripeSession)) {
      Intents.current = {
        key: params.stripeSession,
        value: 0
      };
      localStorage.setItem('CieloIntent', JSON.stringify(Intents.current));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleSubmit = async (event) => {
    event.preventDefault();
    setProcessing(true);

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    const payload = await stripe.confirmPayment({
      elements,
      redirect: 'if_required',
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: `${window.location.origin}/payment/success/order/${params.stripeSession}`
      }
    });
    const request = `buy/success/${params.stripeSession}`;
    if (payload.paymentIntent) {
      if (payload.paymentIntent.status === 'succeeded') {
        axios({
          method: 'POST',
          url: request,
          headers: {
            'Access-Key': 'rTsWwm80NBdjlbFonLNv8sjrql+T2e29ckLG5II794X+oy+MS5XtvcbbJloZ'
          },
          data: { success: true }
        }).then((response) => {
          navigate(`/payment/success/order/${params.stripeSession}`);
          setProcessing(false);
        });
      }
    } else if (payload.error) {
      handleToastState(payload.error.message, 'danger');
      if (Intents.current.value === 2) {
        axios({
          method: 'POST',
          url: request,
          headers: {
            'Access-Key': 'rTsWwm80NBdjlbFonLNv8sjrql+T2e29ckLG5II794X+oy+MS5XtvcbbJloZ'
          },
          data: { success: false }
        }).then((response) => {
          navigate(`/payment/failed/order/locked`);
          setProcessing(false);
        });
      }
      Intents.current.value++;
      localStorage.setItem('CieloIntent', JSON.stringify(Intents.current));
      setProcessing(false);
    }
  };
  return (
    <div className="" id="WrapPay">
      <div className="Content">
        <div className="card-group">
          <div className="card">
            <div className="card-header">
              <h1>PAY</h1>
              <h3>${decimalPriceNumber(detailPay.totalAmount)}</h3>

              <div className="product">
                <p>{detailPay.product.name}</p>
                <span>
                  ${decimalPriceNumber(detailPay.product.amount)}{' '}
                  <small>{detailPay.currency}</small>
                </span>
              </div>
              {detailPay.fees.map((f, idx) => (
                <div className="product" key={idx}>
                  <p>{f.name}</p>
                  <span>
                    ${decimalPriceNumber(f.amount)} <small>{detailPay.currency}</small>
                  </span>
                </div>
              ))}
              <div className="total">
                <p>Total</p>
                <b>${decimalPriceNumber(detailPay.totalAmount)}</b>
              </div>
            </div>
            <div className="card-body">
              <form id="payment-form" onSubmit={handleSubmit}>
                {/* <LinkAuthenticationElement /> */}
                <div className="inputGroup">
                  <PaymentElement />
                </div>
                <Buttons
                  type="submit"
                  disabled={processing || !stripe || !elements}
                  id="submit"
                  className="btn FullButton w-100 mt-4"
                  loading={processing}
                >
                  {'PAY'} ${decimalPriceNumber(detailPay.totalAmount)}
                </Buttons>
                {/* Show any error or success messages */}
                {error && <div id="payment-message">{error.message}</div>}
              </form>
            </div>
            <div className="logos">
              <div className="logo">
                <span>Built by</span>
                <img src={cieloLogo} alt="Cielo Logo" className="CieloLogo" />
              </div>
              <div className="logo">
                <span>Powered by</span>
                <img src={stripeLogo} alt="Stripe Logo" />
              </div>
            </div>
          </div>
          <div className="links">
            <a href="/">Terms & Conditions</a>
            <a href="/">Privacy Policy</a>
          </div>
        </div>
      </div>
      <CToast
        autohide
        visible={toast.show}
        className="align-items-center position-absolute"
        color={toast.color}
        placement="bottom-end"
        style={{ zIndex: 55, right: '15px', top: '15px' }}
        delay={3500}
        onClose={handleToastState}
      >
        <div className="d-flex">
          <CToastBody className="text-white">{toast.message}</CToastBody>
          <CToastClose className="me-2 m-auto" white />
        </div>
      </CToast>
    </div>
  );
}
CheckoutForm.propTypes = {
  detailPay: PropTypes.object
};

const NavigateOut = ({ url }) => {
  const [infoPay, setInfoPay] = useState(null);
  const { methodCall } = usePayHook();
  let params = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    getInfoPay(params.stripeSession);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getInfoPay = async (id) => {
    const { data, err } = await methodCall('getInfoPay', id);
    if (data) {
      setInfoPay(data);
    } else if (err) {
      if (err.status !== 'SALES') {
        navigate(`/payment/failed/order/invalid`);
      } else {
        navigate(`/payment/success/order/${params.stripeSession}`);
      }
    }
  };
  const stripePromise = loadStripe(process.env.REACT_APP_ACCESS_KEY_STRIPE);
  const appearance = {
    theme: 'stripe',
    rules: {
      '.Label': {
        fontSize: '12px',
        fontWeight: '700',
        marginBottom: '12px'
      },
      '.Input': {}
    }
  };

  return (
    <div>
      {infoPay && (
        <Elements
          // options={{ clientSecret: infoPay.clientSecret, appearance, locale: 'en' }}
          options={{
            clientSecret: infoPay.clientSecret,
            appearance,
            locale: 'en'
          }}
          stripe={stripePromise}
        >
          <CheckoutForm detailPay={infoPay} />
        </Elements>
      )}
    </div>
  );
};

NavigateOut.propTypes = {
  url: PropTypes.string.isRequired
};

export default NavigateOut;
