import React, { createContext, useState } from 'react';
import PropTypes from 'prop-types';
export const ApiDocumentationContext = createContext();

export const ApiDocumentationProvider = ({ children }) => {
  const [documentationData, setDocumentationData] = useState(null);

  return (
    <ApiDocumentationContext.Provider value={{ documentationData, setDocumentationData }}>
      {children}
    </ApiDocumentationContext.Provider>
  );
};

ApiDocumentationProvider.propTypes = {
  children: PropTypes.element.isRequired
};
